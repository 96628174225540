import {uuid} from "vue-uuid";

const customerInfo = () =>{
    const localStorageData = JSON.parse(localStorage.getItem('g1g'));
    if (localStorageData.user.user !== null) {
        return localStorageData.user.user;
    }
    else {
       // console.log(localStorageData.itira.travelerInfo);
        if (localStorageData.itira.travelerInfo === null) {
            return null;
        }
        return localStorageData.itira.travelerInfo;
    }
}

let eventId = uuid.v4();

export { eventId, customerInfo };
