export const getters = {
  allCountries(state) {
    return state.allCountries;
  },
  onlyCountries(state) {
    if (state.allCountries != null) {
      const countries = state.allCountries.map((country) => {
        return country.name;
      });
      return countries;
    }

    return [];
  },
  countriesWithIso(state) {
    if (state.allCountries != null) {
      const allcountries = state.allCountries.map(({ name,iso3,iso2 }) => {
          return{
            name,
            iso3,
            iso2
          }
      });
      return allcountries;
    }

    return [];
  },


  status(state) {
    return state.status;
  },
  cities(state) {
    if (state.cities != null) {
      const cities = state.cities.map((country) => {
        return country.name;
      });
      return cities;
    }

    return [];
  },
};
