import { createApp } from "vue";
import App from "./App.vue";

//importing all plugins folder
import "@/plugins";
import { plugins } from "@/plugins/allPlugins.js";
//importing all style
import "@/assets/scss/styleabstract.scss";
const app = createApp(App);
// import { router } from "@/router";
// app.use(router);
plugins.map(({ plugin, params }) => app.use(plugin, params));
app.mount("#app");

