//dashboard routes

const UpdateEmergencyContact = () => import("@/components/dashboard/forms/updateEmergencyContact.vue");
const UpdateBeneficiaryInfo = () => import("@/components/dashboard/forms/updateBeneficiaryInfo.vue");
const UserProfile = () => import("@/pages/userProfile.vue");
const GetReceipt = () => import("@/components/dashboard/getReceipt.vue");
const Dashboard = () => import("@/pages/dashboard.vue");
const DashboardPlanDetails = () => import("@/pages/dashboardPlanDetails.vue");
const ExtendpolicyLayout = () => import("@/pages/extendpolicyLayout.vue");

//claims
const DownloadClaimsTypes = () =>
    import("@/components/claims/downloadClaims.vue");
const AllClaims = () => import("@/components/claims/allClaims.vue");
const ClaimDetails = () => import("@/components/claims/claimDetails.vue");
// const AddNewClaims = () => import("@/components/claims/newAddNewClaims.vue");
const AddNewClaims = () => import("@/components/claims/addNewClaim.vue");
const FormType = () => import("@/components/claims/landing/formType.vue");
const MedicalCareFromOne = () =>
    import("@/components/claims/forms/medicalCare/medicalCareFromOne.vue");
const TripCancellFromOne = () =>
    import("@/components/claims/forms/tripCancellation/tripCancellFromOne.vue");
const TripCancellFromTwo = () =>
    import("@/components/claims/forms/tripCancellation/tripCancellFromTwo.vue");
const ClaimType = () => import("@/components/claims/claimType.vue");
const ClaimDate = () => import("@/components/claims/claimDate.vue");
const ReimbursementTravelers = () =>
    import("@/components/claims/reimbursementTravelers.vue");
const ReimbursementMethods = () =>
    import("@/components/claims/reimbursementMethods.vue");
const ReimbursementByMail = () =>
    import("@/components/claims/reimbursementByMail.vue");
const ReimbursementDirectDeposit = () =>
    import("@/components/claims/reimbursementDirectDeposit.vue");
const Authorization = () => import("@/components/claims/authorization.vue");
const DocRequirements = () => import("@/components/claims/docRequirements.vue");
const TripCancellation = () =>
    import("@/components/claims/tripCancellation.vue");
const TripCancellationAffected = () =>
    import(
        "@/components/claims/forms/tripCancellation/tripCancellationDates.vue"
        );
const TripCancellationDates = () =>
    import("@/components/claims/tripCancellationDates.vue");
const SupplierInformation = () =>
    import("@/components/claims/supplierInformation.vue");
const TripCancellationInformation = () =>
    import("@/components/claims/tripCancellationInformation.vue");
const TravelDelayInformation = () =>
    import("@/components/claims/forms/travelDelay/travelDelayInformation.vue");
const LossInformation = () => import("@/components/claims/lossInformation.vue");
const ClaimSubmit = () => import("@/components/claims/claimSubmit.vue");

const UploadFile = () => import("@/components/claims/uploadFile.vue");
//

//extend dates routes

const ViewTravelDates = () =>
    import("@/components/dashboard/tabs/editTravelDates.vue");
const StartTravelDate = () =>
    import("@/components/dashboard/forms/travelDateStart.vue");
const EndTravelDate = () =>
    import("@/components/dashboard/forms/travelDateEnd.vue");
const ExtendPolicyEndDate = () =>
    import("@/components/dashboard/forms/extendPolicyEndDate.vue");

// extent policy travelers routes

const TravelDetails = () =>
    import("@/components/dashboard/tabs/travelDetails.vue");
const TravelerName = () => import("@/components/dashboard/forms/travelDetailsName.vue");



const ExtendPolicy = () =>
    import("@/components/dashboard/tabs/extendPolicy.vue");

const EditDestination = () =>
    import("@/components/dashboard/tabs/editDestinationTabs.vue");
const EditTripCost = () =>
    import("@/components/dashboard/tabs/editTripCostInner.vue");
const EditTripCostEdit = () =>
    import("@/components/dashboard/forms/travelTripCost.vue");
const CancelPolicy = () =>
    import("@/components/dashboard/tabs/cancelPolicy.vue");
const EditBeneficiary = () =>
    import("@/components/dashboard/tabs/editBeneficiary.vue");
const EditEmergencyContact = () =>
    import("@/components/dashboard/tabs/editEmergencyContact.vue");

const TravelerDOB = () => import("@/components/dashboard/forms/travelDetailsDOB.vue");
import TravelerCitizenship from "@/components/dashboard/forms/travelDetailsCitizenship.vue";
import TravelerResidence from "@/components/dashboard/forms/travelDetailsResidence.vue";
import TravelerEmail from "@/components/dashboard/forms/travelDetailsEmail.vue";
// import Phone from "@/components/dashboard/forms/travelDetailsPhone.vue";
import TravelerAddress from "@/components/dashboard/forms/travelDetailsAddress.vue";
import MissedConnectionInformation from "@/components/claims/forms/missedConnection/MissedConnectionInformation.vue";

const routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        props: { default: true, layout: "Index", footer: true },
        meta: {
            isAuth: true,
        },


        children: [
            {
                path: "download-claim-type-forms/:id",
                name: "DownloadClaimsTypes",
                component: DownloadClaimsTypes,
            },

            {
                path: "extend-policy-plans",
                name: "userProfile",
                component: UserProfile,
            },
            {
                path: "get-receipt/:order_no",
                name: "GetReceipt",
                component: GetReceipt,
            },
            {
                path: "extend-policy-plan-detail/:id",
                name: "DashboardPlanDetails",
                component: DashboardPlanDetails,
            },


            {
                path: "extend-policy-plan-detail/edit",
                name: "ExtendpolicyLayout",
                component: ExtendpolicyLayout,
                children: [
                    {
                        path: "travel-details/:order_no",
                        name: "travelDetails",
                        component: TravelDetails,
                    },
                    {
                        path: "edit-name-traveler/:order_no",
                        name: "TravelerName",
                        component: TravelerName,
                    },
                    {
                        path: "edit-dob-traveler/:order_no",
                        name: "TravelerDOB",
                        component: TravelerDOB,
                    },
                    {
                        path: "edit-citizenship-traveler/:order_no",
                        name: "TravelerCitizenship",
                        component: TravelerCitizenship,
                    },
                    {
                        path: "edit-residence-traveler/:order_no",
                        name: "TravelerResidence",
                        component: TravelerResidence,
                    },
                    {
                        path: "edit-email-traveler/:order_no",
                        name: "TravelerEmail",
                        component: TravelerEmail,
                    },
                    {
                        path: "edit-phone-traveler/:order_no",
                        name: "TravelDetails",
                        component: TravelDetails,
                    },
                    {
                        path: "edit-address-traveler/:order_no",
                        name: "TravelerAddress",
                        component: TravelerAddress,
                    },
                    {
                        path: "view-travel-dates/:order_no",
                        name: "ViewTravelDates",
                        component: ViewTravelDates,
                    },
                    {
                        path: "edit-start-date/:order_no",
                        name: "StartTravelDate",
                        component: StartTravelDate,
                    },

                    {
                        path: "edit-end-date/:order_no",
                        name: "EndTravelDate",
                        component: EndTravelDate,
                    },
                    {
                        path: "edit-end-date-extend-policy/:order_no",
                        name: "extendPolicyEndDate",
                        component: ExtendPolicyEndDate,
                    },
                    {
                        path: "extend-policy/:order_no",
                        name: "ExtendPolicy",
                        component: ExtendPolicy,
                    },
                    {
                        path: "destination/:order_no",
                        name: "EditDestination",
                        component: EditDestination,
                    },
                    {
                        path: "trip-cost/:order_no",
                        name: "EditTripCost",
                        component: EditTripCost,
                    },
                    {
                        path: "trip-cost-edit/:order_no",
                        name: "EditTripCostEdit",
                        component: EditTripCostEdit,
                    },
                    {
                        path: "cancel-policy/:order_no",
                        name: "CancelPolicy",
                        component: CancelPolicy,
                    },
                    {
                        path: "beneficiary/:order_no",
                        name: "EditBeneficiary",
                        component: EditBeneficiary,
                    },
                    {
                        path: "update-beneficiary/:order_no",
                        name: "UpdateBeneficiaryInfo",
                        component: UpdateBeneficiaryInfo,
                    },
                    {
                        path: "emergency/:order_no",
                        name: "EditEmergencyContact",
                        component: EditEmergencyContact,
                    },
                    {
                        path: "update-emergency-contact/:order_no",
                        name: "UpdateEmergencyContact",
                        component: UpdateEmergencyContact,
                    },


                ],
            },
            {
                path: "claims/all-claims/:id",
                name: "AllClaims",
                component: AllClaims,
            },
            {
                path: "claims/claim-details/:id",
                name: "ClaimDetails",
                component: ClaimDetails,
            },
            {
                path: "claims/add-new-claim",
                name: "AddNewClaims",
                component: AddNewClaims,
            },
            {
                path: "claims/claim-type",
                name: "ClaimType",
                component: ClaimType,
            },
            {
                path: "claims/claim-date",
                name: "ClaimDate",
                component: ClaimDate,
            },
            {
                path: "claims/:claimFormType",
                name: "FormType",
                component: FormType,
            },

            {
                path: "claims/medical-care/:medicalCareFromOne",
                name: "MedicalCareFromOne",
                component: MedicalCareFromOne,
            },

            {
                path: "claims/trip-cancellation/:tripCancellFromOne",
                name: "TripCancellFromOne",
                component: TripCancellFromOne,
            },

            {
                path: "claims/trip-cancellation/:tripCancellFromOne/:tripCancellFromTwo",
                name: "TripCancellFromTwo",
                component: TripCancellFromTwo,
            },

            {
                path: "claims/reimbursement-travelers",
                name: "ReimbursementTravelers",
                component: ReimbursementTravelers,
            },
            {
                path: "claims/reimbursement-methods",
                name: "ReimbursementMethods",
                component: ReimbursementMethods,
            },
            {
                path: "claims/reimbursement-mail",
                name: "ReimbursementByMail",
                component: ReimbursementByMail,
            },
            {
                path: "claims/reimbursement-direct-deposit",
                name: "ReimbursementDirectDeposit",
                component: ReimbursementDirectDeposit,
            },
            {
                path: "claims/authorization",
                name: "Authorization",
                component: Authorization,
            },
            {
                path: "claims/doc-require",
                name: "DocRequirements",
                component: DocRequirements,
            },
            // {
            //   path: "claims/trip-cancellation",
            //   name: "TripCancellation",
            //   component: TripCancellation,
            // },
            {
                path: "claims/trip-cancellation-affected",
                name: "TripCancellationAffected",
                component: TripCancellationAffected,
            },
            {
                path: "claims/travel-delay-information",
                name: "TravelDelayInformation",
                component: TravelDelayInformation,
            },
            {
                path: "claims/missed-connection-information",
                name: "MissedConnectionInformation",
                component:MissedConnectionInformation,
            },
            {
                path: "claims/trip-cancellation-dates",
                name: "TripCancellationDates",
                component: TripCancellationDates,
            },
            {
                path: "claims/supplier-information",
                name: "SupplierInformation",
                component: SupplierInformation,
            },
            {
                path: "claims/trip-cancellation-information",
                name: "TripCancellationInformation",
                component: TripCancellationInformation,
            },
            {
                path: "claims/loss-information",
                name: "LossInformation",
                component: LossInformation,
            },
            {
                path: "claims/claim-submit",
                name: "ClaimSubmit",
                component: ClaimSubmit,
            },
            {
                path: "claims/uploadFile/:id",
                name: "UploadFile",
                component: UploadFile,
            },
        ],
    },
];

export default routes;
