import {formatToISODate} from "@/helpers/dateFormat";

export const getters = {

    getCustomerInfo(state) {
        if (state.user != null){
            return {
                city:state.user.city,
                country:state.user.country,
                country_code:state.user.country_code,
                created_at:formatToISODate(state.user.created_at),
                email:state.user.email,
                email_verified_at:formatToISODate(state.user.email_verified_at),
                first_name:state.user.first_name,
                image_path:state.user.image_path,
                is_active:state.user.is_active,
                languages:state.user.languages,
                last_name:state.user.last_name,
                phone_number:state.user.phone_number,
                profile_image_url:state.user.profile_image_url,
                provider_name:state.user.provider_name,
                state:state.user.state,
                street_address:state.user.street_address,
                trial_ends_at:state.user.trial_ends_at,
                updated_at:formatToISODate(state.user.updated_at),
                user_id:state.user.user_id,
                user_uuid:state.user.uuid,
                zip_code:state.user.zip_code,
            };
        }else {

            return null;
        }



    }




};
