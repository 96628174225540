import { toRaw } from "vue";

export const ISO3 = (countries, countryName) => {
  return toRaw(countries.value).find(({ name }) => name == countryName).iso3;
};
export const ISO2 = (countries, countryName) => {
  return countries.find(({ name }) => name == countryName).iso2;
};
export const getStatesOfCountry = (countries, countryName) => {
  let getCountry = toRaw(countries.value).filter(
    ({ name }) => name == countryName
  );
  let { states } = getCountry[0];
  return states;
};

export const ISO3ToFullName = (countries, comingIso3) => {
  // return toRaw(countries.value).find(({ iso3 }) => iso3 == comingIso3).name;
};

export const ISO2ToFullName = (countries, comingIso2) => {
  return countries.find(({ iso2 }) => iso2 == comingIso2).name
};
