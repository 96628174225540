//bugssnack
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG,
  plugins: [new BugsnagPluginVue()],
});

const bugsnagVue = Bugsnag.getPlugin("vue");

import { store } from "@/store";
import { router } from "@/router";
import VueSkeletor from "./vuesekeleton";
import vSelect from "vue-select";
import { createHead } from "@vueuse/head";

import { SchemaOrgUnheadPlugin } from "@unhead/schema-org";
import VuePaycard from "vue-paycard";
import Notifications from "@kyvg/vue3-notification";
import VueLazyLoad from "vue3-lazyload";
import VueTheMask from "vue-the-mask";
import vue3GoogleLogin from "vue3-google-login";
import { createMetaManager } from 'vue-meta'

export const plugins = [
  { plugin: bugsnagVue, params: {} },
  { plugin: store, params: {} },
  { plugin: router, params: {} },
  { plugin: VueSkeletor, params: {} },
  {
    plugin: createHead({
      plugins: [
        SchemaOrgUnheadPlugin({
          host: "https://g1g.com",
        }),
      ],
    }),
    params: {},
  },
  { plugin: Notifications, params: {} },
  { plugin: vSelect, params: {} },
  { plugin: VuePaycard, params: {} },
  { plugin: VueLazyLoad, params: {} },
  { plugin: VueTheMask, params: {} },
  { plugin: vSelect, params: {} },
  { plugin: createMetaManager(), params: {} },
  {
    plugin: vue3GoogleLogin,
    params: {
      clientId: process.env.VUE_APP_GOOGLE_SERVER_KEY,
    },
  },
];
