export default {
  STATE_STATUSES(state, payload) {
    state.status = payload;
  },
  updateTravelerLoading(state, payload) {
    state.updateTravelerLoading = payload;
  },
  setTravelers(state, payload) {
    state.travelers = payload;
  },
  setTravelerDates(state, payload) {
    state.travelerDates = payload;
  },
  setTravelerDatesLoading(state, payload) {
    state.travelerDatesLoading = payload;
  },
  updateTravelers(state, payload) {
    state.travelers = payload;
  },
  setTravelersDate(state, payload) {
    state.travelerDates = payload;
  },
  updateTravelersDate(state, payload) {
    state.travelerDates = payload;
  },
  setExtendPolicy(state, payload) {
    state.extendPolicy = payload;
  },
  updateExtendPolicy(state, payload) {
    state.extendPolicy = payload;
  },
  setEditDestination(state, payload) {
    state.editDestination = payload;
  },
  updateEditDestination(state, payload) {
    state.editDestination = payload;
  },
  setCancelPolicy(state, payload) {
    state.cancelPolicy = payload;
  },
  updateCancelPolicy(state, payload) {
    state.cancelPolicy = payload;
  },
  setSelectedTraveler(state, payload) {
    state.selectedTraveler = payload;
  },
  setComponentId(state, payload) {
    state.componentId = payload;
  },

  setTripCostLoading(state, payload) {
    state.tripCostLoading = payload;
  },
  setTripCost(state, payload) {
    state.tripCost = payload;
  },
  setBeneficiaryInfoLoading(state, payload) {
    state.beneficiaryInfoLoading = payload;
  },
  setBeneficiaryInfo(state, payload) {
    state.beneficiaryInfo = payload;
  },
  updateBeneficiaryInfo(state, payload) {
    state.beneficiaryInfo = payload;
  },
  setEmergencyContactLoading(state, payload) {
    state.emergencyContactLoading = payload;
  },
  setEmergencyContact(state, payload) {
    state.emergencyContact = payload;
  },
  updateEmergencyContact(state, payload) {
    state.emergencyContact = payload;
  },
  getReceiptDataStatus(state, payload) {
    state.status = payload;
  },
  getReceiptData(state, payload) {
    state.receipt = payload;
  }
};
