import { actions } from './action';
import mutations from './mutations';
import { getters } from './getters';

import { STATE_STATUSES } from '@/helpers/constants';
import { reactive } from '@vue/reactivity';
import { useLocalStorage } from '@vueuse/core';
const state = reactive(
  useLocalStorage('g1gcountries', {
    allCountries: null,
    status: STATE_STATUSES.INIT,
    cities: null,
  })
);

const plans = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default plans;
