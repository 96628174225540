import { actions } from "./action";
import mutations from "./mutations";
import { getters } from "./getters";
import { reactive } from "@vue/reactivity";
import { STATE_STATUSES } from "@/helpers/constants";
const state = reactive({
  itira: {},
  travelerReasons: null,
  travelerConcerns: null,
  travelerInfo: null,
  destinationType: null,
  airportsOriginCountry: null,
  airportsOriginCountryLoader: STATE_STATUSES.INIT,
  airportsDestinationCountry: null,
  airportsDestinationCountryLoader: STATE_STATUSES.INIT,
  setMultipleDestinations: [],
  multipleDestinations: [],
  addMultiTraveler: [],
  redirectingFromForProducts: null,
  riskScors: null,
  status: STATE_STATUSES.INIT,
  countriesLoading: STATE_STATUSES.INIT,
  loadingSender: STATE_STATUSES.INIT,
  widgetParams: null,
  affiliateId: null,

  travelerNumbers: {
    persons: 1,
    pets: 0,
  },
});
const itira = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
export default itira;
