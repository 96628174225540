/*

import AreDualCitizensEligible from '@/pages/faqs/are-dual-citizens-eligible-for-visitors-travel-health-insurance.vue';
import AreElectronicsCoveredInCheckedLuggage from '@/pages/faqs/are-electronics-covered-while-they-are-in-checked-luggage.vue';
import AreLaptopsSmartphonesTabletsAndCamerasCovered from '@/pages/faqs/are-laptops-smartphones-tablets-and-cameras-covered-by-travel-insurance.vue';
import AreThereAnyAgeLimits from '@/pages/faqs/are-there-any-age-limits.vue';
import AreThereAnyDiscounts from '@/pages/faqs/are-there-any-discounts.vue';
import AreYouPlanningATripToTheUSAAndCuriousAboutHowVisitorsHealthInsuranceWorks from '@/pages/faqs/are-you-planning-a-trip-to-the-usa-and-curious-about-how-visitors-health-insurance-works.vue';
import CanIAddANewMemberToMyVisitorsInsurancePolicyAfterIBuy from '@/pages/faqs/can-i-add-a-new-member-to-my-visitors-insurance-policy-after-i-buy.vue';
import CanIAssignMyInsuranceBenefits from '@/pages/faqs/can-i-assign-my-insurance-benefits-to-a-hospital-physician-or-other-provider.vue';
import CanIBuyTravelInsuranceAfterIDepart from '@/pages/faqs/can-i-buy-travel-insurance-after-i-depart.vue';
import CanIBuyVisitorHealthInsuranceIfImAlreadyInTheUSA from '@/pages/faqs/can-i-buy-visitor-health-insurance-if-im-already-in-the-usa.vue';
import CanIBuyVisitorsInsuranceAfterIveAlreadyStartedMyTrip from '@/pages/faqs/can-i-buy-visitors-insurance-after-ive-already-started-my-trip.vue';
import CanIBuyVisitorsInsuranceCoverageBeforeTripBegins from '@/pages/faqs/can-i-buy-visitors-insurance-coverage-for-the-days-before-my-trip-begins.vue';
import CanIBuyVisitorsInsuranceForParentsOver70 from '@/pages/faqs/can-i-buy-visitors-insurance-for-my-parents-over-70-coming-to-the-usa.vue';
import CanICancelMyTripDueToCivilUnrest from '@/pages/faqs/can-i-cancel-my-trip-due-to-civil-unrest-and-be-reimbursed-by-my-travel-insurance.vue';
import CanIChangeMyPrimaryCarePhysician from '@/pages/faqs/can-i-change-my-primary-care-physician.vue';
import CanIGetImmediateCoverageOnline from '@/pages/faqs/can-i-get-immediate-coverage-online.vue';
import CanIGetTripCancellationCoverageForCovid19 from '@/pages/faqs/can-i-get-trip-cancellation-coverage-for-covid-19-and-other-pandemics.vue';
import CanIMakeChangesToMyG1GInsuranceApplication from '@/pages/faqs/can-i-make-changes-to-my-g1g-visitors-travel-insurance-application.vue';
import CanIMakeChangesToPolicyDuringLifeOfPolicy from '@/pages/faqs/can-i-make-changes-to-policy-during-the-life-of-the-policy.vue';
import CanIPurchaseTravelInsuranceAfterCivilUnrest from '@/pages/faqs/can-i-purchase-travel-insurance-after-civil-unrest-has-already-begun-at-my-destination.vue';
import CanIPurchaseVisitorsInsuranceIfTransitioningToPermanentResidency from '@/pages/faqs/can-i-purchase-visitors-insurance-if-i-am-transitioning-to-permanent-residency-in-the-usa.vue';
import CanIRenewMyPolicyAndForHowLong from '@/pages/faqs/can-i-renew-my-policy-and-for-how-long.vue';
import CanISeeAnyDoctorWithMyPlan from '@/pages/faqs/can-i-see-any-doctor-with-my-plan.vue';
import CanITransferMyTravelInsuranceToDifferentTrip from '@/pages/faqs/can-i-transfer-my-travel-insurance-to-a-different-trip.vue';
import CanTravelInsuranceCoverCovid19 from '@/pages/faqs/can-travel-insurance-cover-covid-19.vue';
import CanVisitorsInsuranceBeBoughtAfterBooking from '@/pages/faqs/can-visitors-insurance-be-bought-after-booking.vue';
import CanVisitorsInsuranceBeCanceled from '@/pages/faqs/can-visitors-insurance-be-canceled.vue';
import CanYouExtendYourTravelInsurancePolicy from '@/pages/faqs/can-you-extend-your-travel-insurance-policy.vue';
import CanYouMailMyIdCardToMe from '@/pages/faqs/can-you-mail-my-id-card-to-me.vue';
import DoINeedToGetAPhysicalExamOrDiscloseAnything from '@/pages/faqs/do-i-need-to-get-a-physical-exam-or-disclose-anything-from-my-medical-records-to-purchase-a-plan.vue';
import DoProvidersOfferACoolingOffPeriod from '@/pages/faqs/do-providers-offer-a-cooling-off-period.vue';
import DoesMyInsurancePolicyCoverTreatmentForMonkeypox from '@/pages/faqs/does-my-insurance-policy-cover-treatment-for-monkeypox.vue';
import DoesMyTravelInsuranceCoverMeIfIGetSickWithCovid19 from '@/pages/faqs/does-my-travel-insurance-cover-me-if-i-get-sick-with-covid-19.vue';
import DoesTravelInsuranceCoverLossTheftOrDamageToElectronics from '@/pages/faqs/does-travel-insurance-cover-loss-theft-or-damage-to-electronics.vue';
import DoesVisitorsInsuranceCoverBloodWorkAndLabTests from '@/pages/faqs/does-visitors-insurance-cover-blood-work-and-lab-tests.vue';
import DoesVisitorsInsuranceCoverMeWhileTravelingToMyDestination from '@/pages/faqs/does-visitors-insurance-cover-me-while-traveling-to-my-destination.vue';
import DoesVisitorsInsuranceCoverUrgentCareVisits from '@/pages/faqs/does-visitors-insurance-cover-urgent-care-visits.vue';
import HereFaqTitleEdit from '@/pages/faqs/here-faq-title-edit.vue';
import HowCanIAccessDetailsOfMyVisitorsTravelHealthInsurancePlanAfterPurchase from '@/pages/faqs/how-can-i-access-the-details-of-my-visitors-travel-health-insurance-plan-after-purchase.vue';
import HowCanIRenewOrExtendMyVisitorsTravelHealthInsurancePlanWithG1G from '@/pages/faqs/how-can-i-renew-or-extend-my-visitors-travel-health-insurance-plan-with-g1g.vue';
import HowCanYouAccessYourTravelInsurancePolicyDocumentsOnline from '@/pages/faqs/how-can-you-access-your-travel-insurance-policy-documents-online.vue';
import HowCanYouMakeChangesToYourTravelInsurancePlan from '@/pages/faqs/how-can-you-make-changes-to-your-travel-insurance-plan.vue';
import HowDoIBuyAVisitorHealthInsurancePlanForParentsTravelingFromIndiaToTheUSA from '@/pages/faqs/how-do-i-buy-a-visitor-health-insurance-plan-for-parents-traveling-from-india-to-the-usa.vue';
import HowDoIBuyVisitorsTravelMedicalInsuranceForMyTripToTheUSA from '@/pages/faqs/how-do-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa.vue';
import HowDoIChooseACoInsuranceAmountWhenBuyingG1GVisitorsInsurance from '@/pages/faqs/how-do-i-choose-a-co-insurance-amount-when-buying-g1g-visitors-insurance.vue';
import HowDoIFileAClaim from '@/pages/faqs/how-do-i-file-a-claim.vue';
import HowDoIFindADoctorWhenVisitingTheUSAAsATourist from '@/pages/faqs/how-do-i-find-a-doctor-when-visiting-the-usa-as-a-tourist.vue';
import HowDoIGetMedicineFromAPharmacy from '@/pages/faqs/how-do-i-get-medicine-from-a-pharmacy.vue';
import HowDoIKnowIfMyTravelInsuranceCoversCivilUnrest from '@/pages/faqs/how-do-i-know-if-my-travel-insurance-covers-civil-unrest.vue';
import HowDoesDirectBillingWorkWithInNetworkProvidersForVisitorsMedicalInsuranceToTheUSA from '@/pages/faqs/how-does-direct-billing-work-with-in-network-providers-for-visitors-medical-insurance-to-the-usa.vue';
import HowDoesPaymentForMedicalTreatmentWorkWithVisitorsTravelHealthInsurance from '@/pages/faqs/how-does-payment-for-medical-treatment-work-with-visitors-travel-health-insurance.vue';
import HowDoesTravelInsuranceWork from '@/pages/faqs/how-does-travel-insurance-work.vue';
import HowEarlyShouldIBuyVisitorsTravelMedicalInsuranceForMyTripToTheUSA from '@/pages/faqs/how-early-should-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa.vue';
import HowMuchDoesTravelInsuranceCost from '@/pages/faqs/how-much-does-travel-insurance-cost.vue';
import HowMuchDoesVisitorsTravelHealthInsuranceCostForATripToTheUSA from '@/pages/faqs/how-much-does-visitors-travel-health-insurance-cost-for-a-trip-to-the-usa.vue';
import HowMuchWillIHaveToPayOutOfPocketForTreatmentIfMyInsuranceDoesntCoverMonkeypox from '@/pages/faqs/how-much-will-i-have-to-pay-out-of-pocket-for-treatment-if-my-insurance-doesnt-cover-monkeypox.vue';
import HowSoonCanIStartCoverage from '@/pages/faqs/how-soon-can-i-start-coverage.vue';
import HowTravelInsuranceCoverCovid19 from '@/pages/faqs/how-travel-insurance-cover-covid-19.vue';
import IAlreadyLeftForMyTripIsItTooLateToBuyTravelInsurance from '@/pages/faqs/i-already-left-for-my-trip-is-it-too-late-to-buy-travel-insurance.vue';
import IAmComingToStudyInTheUnitedStatesWhatPolicyIsRightForMe from '@/pages/faqs/i-am-coming-to-study-in-the-united-states-what-policy-is-right-for-me.vue';
import IAmNotAUSResidentCanIStillPurchaseAPolicy from '@/pages/faqs/i-am-not-a-us-resident-can-i-still-purchase-a-policy.vue';
import IAmTravelingInAGroupIsASinglePolicyForTheWholeGroupAvailable from '@/pages/faqs/i-am-traveling-in-a-group-is-a-single-policy-for-the-whole-group-available.vue';
import IAmTravelingWithSmallChildrenWhatHappensIfSomethingHappensToMeDuringTheTrip from '@/pages/faqs/i-am-traveling-with-small-children-what-happens-if-something-happens-to-me-during-the-trip.vue';
import IHaveFamilyFriendsVisitingFromOutsideTheUnitedStatesWhatPolicyShouldIPurchase from '@/pages/faqs/i-have-familyfriends-visiting-from-outside-the-united-states-what-policy-should-i-purchase.vue';
import IfIEndMyTripEarlyCanCancelMyPolicyBeforeItsExpiration from '@/pages/faqs/if-i-end-my-trip-early-can-cancel-my-policy-before-its-expiration.vue';
import ImGoingToStudyAbroadWhatShouldILookForInAPolicy from '@/pages/faqs/im-going-to-study-abroad-what-should-i-look-for-in-a-policy.vue';
import IsAnEmergencyRoomVisitCoveredByMyVisitorsTravelMedicalInsurance from '@/pages/faqs/is-an-emergency-room-visit-covered-by-my-visitors-travel-medical-insurance.vue';
import IsBypassSurgeryConsideredAPreExistingMedicalCondition from '@/pages/faqs/is-bypass-surgery-considered-a-pre-existing-medical-condition.vue';
import IsPregnancyCovered from '@/pages/faqs/is-pregnancy-covered.vue';
import IsTheVirtualIDCardValidOrShouldIWaitForThePhysicalCard from '@/pages/faqs/is-the-virtual-id-card-valid-or-should-i-wait-for-the-physical-card.vue';
import IsThereALimitOnTheValueOfElectronicsThatCanBeCoveredByTravelInsurance from '@/pages/faqs/is-there-a-limit-on-the-value-of-electronics-that-can-be-covered-by-travel-insurance.vue';
import IsThereAMonthlyPaymentPlanAvailableForVisitorsInsurance from '@/pages/faqs/is-there-a-monthly-payment-plan-available-for-visitors-insurance.vue';
import IsTravelInsuranceActuallyWorthIt from '@/pages/faqs/is-travel-insurance-actually-worth-it.vue';
import MyParentsHaveNotArrivedInTheUnitedStatesYetCanIPurchaseCoverageForThem from '@/pages/faqs/my-parents-have-not-arrived-in-the-united-states-yet-can-i-purchase-coverage-for-them.vue';
import UnderstandingTravelInsuranceClaimProcessingTimeWhatToExpect from '@/pages/faqs/understanding-travel-insurance-claim-processing-time-what-to-expect.vue';
import WhatAVisaLetterAndHowDoIKnowIfINeedOne from '@/pages/faqs/what-a-visa-letter-and-how-do-i-know-if-i-need-one.vue';
import WhatArePreExistingConditions from '@/pages/faqs/what-are-pre-existing-conditions.vue';
import WhatAreTheDifferencesBetweenFixedAndComprehensiveVisitorsInsurancePlans from '@/pages/faqs/what-are-the-differences-between-fixed-and-comprehensive-visitors-insurance-plans.vue';
import WhatAreTheTravelInsuranceRequirementsForASchengenVisa from '@/pages/faqs/what-are-the-travel-insurance-requirements-for-a-schengen-visa.vue';
import WhatDoes247WorldwideSupportAndClaimAssistanceMean from '@/pages/faqs/what-does-247-worldwide-support-and-claim-assistance-mean.vue';
import WhatHappensIfIAmHospitalizedAbroad from '@/pages/faqs/what-happens-if-i-am-hospitalized-abroad.vue';
import WhatHappensIfIMissMyRenewalDate from '@/pages/faqs/what-happens-if-i-miss-my-renewal-date.vue';
import WhatHappensIfMyLuggageIsLostDelayed from '@/pages/faqs/what-happens-if-my-luggage-is-lostdelayed.vue';
import WhatIfIAmInjuredInACarAccidentAbroad from '@/pages/faqs/what-if-i-am-injured-in-a-car-accident-abroad.vue';
import WhatIfMyVisaStatusChanges from '@/pages/faqs/what-if-my-visa-status-changes.vue';
import WhatInformationIsRequiredForPurchasingAVisitorsInsurancePlan from '@/pages/faqs/what-information-is-required-for-purchasing-a-visitors-insurance-plan.vue';
import WhatInformationIsRequiredOfMeToPurchaseAPolicy from '@/pages/faqs/what-information-is-required-of-me-to-purchase-a-policy.vue';
import WhatIsAPPO from '@/pages/faqs/what-is-a-ppo.vue';
import WhatIsAPrimaryCarePhysician from '@/pages/faqs/what-is-a-primary-care-physician.vue';
import WhatIsAnHMO from '@/pages/faqs/what-is-an-hmo.vue';
import WhatIsCoronavirusDisease2019COVID19 from '@/pages/faqs/what-is-coronavirus-disease-2019-covid-19.vue';
import WhatIsPOS from '@/pages/faqs/what-is-pos.vue';
import WhatIsSingleOccupancyCoverageAndWhatTypesOfSituationDoesThisCoverageAppliesTo from '@/pages/faqs/what-is-single-occupancy-coverage-and-what-types-of-situation-does-this-coverage-applies-to.vue';
import WhatIsTheDifferenceBetweenExtensionAndRenewal from '@/pages/faqs/what-is-the-difference-between-extension-and-renewal.vue';
import WhatIsTheDifferenceBetweenTripCancellationAndCancelForAnyReasonTravelInsurance from '@/pages/faqs/what-is-the-difference-between-trip-cancellation-and-cancel-for-any-reason-travel-insurance.vue';
import WhatIsTheMaximumAgeLimitForAnIndividualToBeEligibleForAdventureTravelInsurance from '@/pages/faqs/what-is-the-maximum-age-limit-for-an-individual-to-be-eligible-for-adventure-travel-insurance.vue';
import WhatIsTravelInsurance from '@/pages/faqs/what-is-travel-insurance.vue';
import WhatIsTravelInsuranceAndWhatDoesItCover from '@/pages/faqs/what-is-travel-insurance-what-does-travel-insurance-cover.vue';
import WhatShouldIDoAfterIvePurchasedAnHMOPlan from '@/pages/faqs/what-should-i-do-after-ive-purchased-an-hmo-plan.vue';
import WhatShouldIDoAfterPurchasingAPPOPlan from '@/pages/faqs/what-should-i-do-after-purchasing-a-ppo-plan.vue';
import WhatShouldILookForInAPolicy from '@/pages/faqs/what-should-i-look-for-in-a-policy.vue';
import WhatShouldILookForInAPolicyIfImUncertainAboutHowLongIllBeTraveling from '@/pages/faqs/what-should-i-look-for-in-a-policy-if-im-uncertain-about-how-long-ill-be-traveling.vue';
import WhatTypesOfElectronicsAreCoveredByTravelInsurance from '@/pages/faqs/what-types-of-electronics-are-covered-by-travel-insurance.vue';
import WhatsTheDifferenceBetweenUrgentCareAndEmergencyServices from '@/pages/faqs/whats-the-difference-between-urgent-care-and-emergency-services.vue';
import WhenIsTravelInsuranceWorthIt from '@/pages/faqs/when-is-travel-insurance-worth-it.vue';
import WhenShouldIBuyCoverage from '@/pages/faqs/when-should-i-buy-coverage.vue';
import WhenShouldIBuyTravelInsurance from '@/pages/faqs/when-should-i-buy-travel-insurance.vue';
import WhoIsEligibleForAdventureTravelInsurance from '@/pages/faqs/who-is-eligible-for-adventure-travel-insurance.vue';
import WhoIsEligibleForVisitorMedicalInsurance from '@/pages/faqs/who-is-eligible-for-visitor-medical-insurance.vue';
import WhoShouldICallIfIHaveProblemsWithMyFlightCruise from '@/pages/faqs/who-should-i-call-if-i-have-problems-with-my-flightcruise.vue';
import WhyAreTravelInsurancePlansCheaperThanDomesticHealthInsurancePlans from '@/pages/faqs/why-are-travel-insurance-plans-cheaper-than-domestic-health-insurance-plans.vue';
import WhyShouldIPurchaseASeparatePolicyForPeople70AndOlder from '@/pages/faqs/why-should-i-purchase-a-separate-policy-for-people-70-and-older.vue';
import WillMyTravelInsuranceCoverMeIfINeedToBeEvacuatedDueToCivilUnrest from '@/pages/faqs/will-my-travel-insurance-cover-me-if-i-need-to-be-evacuated-due-to-civil-unrest.vue';
import WillMyTravelInsuranceCoverMeIfThereIsCivilUnrestAtMyDestination from '@/pages/faqs/will-my-travel-insurance-cover-me-if-there-is-civil-unrest-at-my-destination.vue';
import WillPlansCoverBaggageLossAndTripCancellation from '@/pages/faqs/will-plans-cover-baggage-loss-and-trip-cancellation.vue';

const routes = [
    {
        path: '/faqs/are-dual-citizens-eligible-for-visitors-travel-health-insurance',
        name: 'AreDualCitizensEligible',
        component: AreDualCitizensEligible,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/are-electronics-covered-while-they-are-in-checked-luggage',
        name: 'AreElectronicsCoveredInCheckedLuggage',
        component: AreElectronicsCoveredInCheckedLuggage,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/are-laptops-smartphones-tablets-and-cameras-covered-by-travel-insurance',
        name: 'AreLaptopsSmartphonesTabletsAndCamerasCovered',
        component: AreLaptopsSmartphonesTabletsAndCamerasCovered,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/are-there-any-age-limits',
        name: 'AreThereAnyAgeLimits',
        component: AreThereAnyAgeLimits,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/are-there-any-discounts',
        name: 'AreThereAnyDiscounts',
        component: AreThereAnyDiscounts,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/are-you-planning-a-trip-to-the-usa-and-curious-about-how-visitors-health-insurance-works',
        name: 'AreYouPlanningATripToTheUSAAndCuriousAboutHowVisitorsHealthInsuranceWorks',
        component: AreYouPlanningATripToTheUSAAndCuriousAboutHowVisitorsHealthInsuranceWorks,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-add-a-new-member-to-my-visitors-insurance-policy-after-i-buy',
        name: 'CanIAddANewMemberToMyVisitorsInsurancePolicyAfterIBuy',
        component: CanIAddANewMemberToMyVisitorsInsurancePolicyAfterIBuy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-assign-my-insurance-benefits-to-a-hospital-physician-or-other-provider',
        name: 'CanIAssignMyInsuranceBenefits',
        component: CanIAssignMyInsuranceBenefits,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-buy-travel-insurance-after-i-depart',
        name: 'CanIBuyTravelInsuranceAfterIDepart',
        component: CanIBuyTravelInsuranceAfterIDepart,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-buy-visitor-health-insurance-if-im-already-in-the-usa',
        name: 'CanIBuyVisitorHealthInsuranceIfImAlreadyInTheUSA',
        component: CanIBuyVisitorHealthInsuranceIfImAlreadyInTheUSA,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-buy-visitors-insurance-after-ive-already-started-my-trip',
        name: 'CanIBuyVisitorsInsuranceAfterIveAlreadyStartedMyTrip',
        component: CanIBuyVisitorsInsuranceAfterIveAlreadyStartedMyTrip,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-buy-visitors-insurance-coverage-for-the-days-before-my-trip-begins',
        name: 'CanIBuyVisitorsInsuranceCoverageBeforeTripBegins',
        component: CanIBuyVisitorsInsuranceCoverageBeforeTripBegins,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-buy-visitors-insurance-for-my-parents-over-70-coming-to-the-usa',
        name: 'CanIBuyVisitorsInsuranceForParentsOver70',
        component: CanIBuyVisitorsInsuranceForParentsOver70,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-cancel-my-trip-due-to-civil-unrest-and-be-reimbursed-by-my-travel-insurance',
        name: 'CanICancelMyTripDueToCivilUnrest',
        component: CanICancelMyTripDueToCivilUnrest,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-change-my-primary-care-physician',
        name: 'CanIChangeMyPrimaryCarePhysician',
        component: CanIChangeMyPrimaryCarePhysician,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-get-immediate-coverage-online',
        name: 'CanIGetImmediateCoverageOnline',
        component: CanIGetImmediateCoverageOnline,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-get-trip-cancellation-coverage-for-covid-19-and-other-pandemics',
        name: 'CanIGetTripCancellationCoverageForCovid19',
        component: CanIGetTripCancellationCoverageForCovid19,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-make-changes-to-my-g1g-visitors-travel-insurance-application',
        name: 'CanIMakeChangesToMyG1GInsuranceApplication',
        component: CanIMakeChangesToMyG1GInsuranceApplication,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-make-changes-to-policy-during-the-life-of-the-policy',
        name: 'CanIMakeChangesToPolicyDuringLifeOfPolicy',
        component: CanIMakeChangesToPolicyDuringLifeOfPolicy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-purchase-travel-insurance-after-civil-unrest-has-already-begun-at-my-destination',
        name: 'CanIPurchaseTravelInsuranceAfterCivilUnrest',
        component: CanIPurchaseTravelInsuranceAfterCivilUnrest,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-purchase-visitors-insurance-if-i-am-transitioning-to-permanent-residency-in-the-usa',
        name: 'CanIPurchaseVisitorsInsuranceIfTransitioningToPermanentResidency',
        component: CanIPurchaseVisitorsInsuranceIfTransitioningToPermanentResidency,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-renew-my-policy-and-for-how-long',
        name: 'CanIRenewMyPolicyAndForHowLong',
        component: CanIRenewMyPolicyAndForHowLong,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-see-any-doctor-with-my-plan',
        name: 'CanISeeAnyDoctorWithMyPlan',
        component: CanISeeAnyDoctorWithMyPlan,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-i-transfer-my-travel-insurance-to-a-different-trip',
        name: 'CanITransferMyTravelInsuranceToDifferentTrip',
        component: CanITransferMyTravelInsuranceToDifferentTrip,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-travel-insurance-cover-covid-19',
        name: 'CanTravelInsuranceCoverCovid19',
        component: CanTravelInsuranceCoverCovid19,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-visitors-insurance-be-bought-after-booking',
        name: 'CanVisitorsInsuranceBeBoughtAfterBooking',
        component: CanVisitorsInsuranceBeBoughtAfterBooking,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-visitors-insurance-be-canceled',
        name: 'CanVisitorsInsuranceBeCanceled',
        component: CanVisitorsInsuranceBeCanceled,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-you-extend-your-travel-insurance-policy',
        name: 'CanYouExtendYourTravelInsurancePolicy',
        component: CanYouExtendYourTravelInsurancePolicy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/can-you-mail-my-id-card-to-me',
        name: 'CanYouMailMyIdCardToMe',
        component: CanYouMailMyIdCardToMe,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/do-i-need-to-get-a-physical-exam-or-disclose-anything-from-my-medical-records-to-purchase-a-plan',
        name: 'DoINeedToGetAPhysicalExamOrDiscloseAnything',
        component: DoINeedToGetAPhysicalExamOrDiscloseAnything,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/do-providers-offer-a-cooling-off-period',
        name: 'DoProvidersOfferACoolingOffPeriod',
        component: DoProvidersOfferACoolingOffPeriod,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/does-my-insurance-policy-cover-treatment-for-monkeypox',
        name: 'DoesMyInsurancePolicyCoverTreatmentForMonkeypox',
        component: DoesMyInsurancePolicyCoverTreatmentForMonkeypox,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/does-my-travel-insurance-cover-me-if-i-get-sick-with-covid-19',
        name: 'DoesMyTravelInsuranceCoverMeIfIGetSickWithCovid19',
        component: DoesMyTravelInsuranceCoverMeIfIGetSickWithCovid19,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/does-travel-insurance-cover-loss-theft-or-damage-to-electronics',
        name: 'DoesTravelInsuranceCoverLossTheftOrDamageToElectronics',
        component: DoesTravelInsuranceCoverLossTheftOrDamageToElectronics,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/does-visitors-insurance-cover-blood-work-and-lab-tests',
        name: 'DoesVisitorsInsuranceCoverBloodWorkAndLabTests',
        component: DoesVisitorsInsuranceCoverBloodWorkAndLabTests,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/does-visitors-insurance-cover-me-while-traveling-to-my-destination',
        name: 'DoesVisitorsInsuranceCoverMeWhileTravelingToMyDestination',
        component: DoesVisitorsInsuranceCoverMeWhileTravelingToMyDestination,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/does-visitors-insurance-cover-urgent-care-visits',
        name: 'DoesVisitorsInsuranceCoverUrgentCareVisits',
        component: DoesVisitorsInsuranceCoverUrgentCareVisits,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/here-faq-title-edit',
        name: 'HereFaqTitleEdit',
        component: HereFaqTitleEdit,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-can-i-access-the-details-of-my-visitors-travel-health-insurance-plan-after-purchase',
        name: 'HowCanIAccessDetailsOfMyVisitorsTravelHealthInsurancePlanAfterPurchase',
        component: HowCanIAccessDetailsOfMyVisitorsTravelHealthInsurancePlanAfterPurchase,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-can-i-renew-or-extend-my-visitors-travel-health-insurance-plan-with-g1g',
        name: 'HowCanIRenewOrExtendMyVisitorsTravelHealthInsurancePlanWithG1G',
        component: HowCanIRenewOrExtendMyVisitorsTravelHealthInsurancePlanWithG1G,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-can-you-access-your-travel-insurance-policy-documents-online',
        name: 'HowCanYouAccessYourTravelInsurancePolicyDocumentsOnline',
        component: HowCanYouAccessYourTravelInsurancePolicyDocumentsOnline,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faq/how-can-you-make-changes-to-your-travel-insurance-plan',
        name: 'HowCanYouMakeChangesToYourTravelInsurancePlan',
        component: HowCanYouMakeChangesToYourTravelInsurancePlan,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-do-i-buy-a-visitor-health-insurance-plan-for-parents-traveling-from-india-to-the-usa',
        name: 'HowDoIBuyAVisitorHealthInsurancePlanForParentsTravelingFromIndiaToTheUSA',
        component: HowDoIBuyAVisitorHealthInsurancePlanForParentsTravelingFromIndiaToTheUSA,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-do-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa',
        name: 'HowDoIBuyVisitorsTravelMedicalInsuranceForMyTripToTheUSA',
        component: HowDoIBuyVisitorsTravelMedicalInsuranceForMyTripToTheUSA,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-do-i-choose-a-co-insurance-amount-when-buying-g1g-visitors-insurance',
        name: 'HowDoIChooseACoInsuranceAmountWhenBuyingG1GVisitorsInsurance',
        component: HowDoIChooseACoInsuranceAmountWhenBuyingG1GVisitorsInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-do-i-file-a-claim',
        name: 'HowDoIFileAClaim',
        component: HowDoIFileAClaim,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-do-i-find-a-doctor-when-visiting-the-usa-as-a-tourist',
        name: 'HowDoIFindADoctorWhenVisitingTheUSAAsATourist',
        component: HowDoIFindADoctorWhenVisitingTheUSAAsATourist,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-do-i-get-medicine-from-a-pharmacy',
        name: 'HowDoIGetMedicineFromAPharmacy',
        component: HowDoIGetMedicineFromAPharmacy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-do-i-know-if-my-travel-insurance-covers-civil-unrest',
        name: 'HowDoIKnowIfMyTravelInsuranceCoversCivilUnrest',
        component: HowDoIKnowIfMyTravelInsuranceCoversCivilUnrest,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-does-direct-billing-work-with-in-network-providers-for-visitors-medical-insurance-to-the-usa',
        name: 'HowDoesDirectBillingWorkWithInNetworkProvidersForVisitorsMedicalInsuranceToTheUSA',
        component: HowDoesDirectBillingWorkWithInNetworkProvidersForVisitorsMedicalInsuranceToTheUSA,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-does-payment-for-medical-treatment-work-with-visitors-travel-health-insurance',
        name: 'HowDoesPaymentForMedicalTreatmentWorkWithVisitorsTravelHealthInsurance',
        component: HowDoesPaymentForMedicalTreatmentWorkWithVisitorsTravelHealthInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-does-travel-insurance-work',
        name: 'HowDoesTravelInsuranceWork',
        component: HowDoesTravelInsuranceWork,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-early-should-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa',
        name: 'HowEarlyShouldIBuyVisitorsTravelMedicalInsuranceForMyTripToTheUSA',
        component: HowEarlyShouldIBuyVisitorsTravelMedicalInsuranceForMyTripToTheUSA,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-much-does-travel-insurance-cost',
        name: 'HowMuchDoesTravelInsuranceCost',
        component: HowMuchDoesTravelInsuranceCost,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-much-does-visitors-travel-health-insurance-cost-for-a-trip-to-the-usa',
        name: 'HowMuchDoesVisitorsTravelHealthInsuranceCostForATripToTheUSA',
        component: HowMuchDoesVisitorsTravelHealthInsuranceCostForATripToTheUSA,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-much-will-i-have-to-pay-out-of-pocket-for-treatment-if-my-insurance-doesnt-cover-monkeypox',
        name: 'HowMuchWillIHaveToPayOutOfPocketForTreatmentIfMyInsuranceDoesntCoverMonkeypox',
        component: HowMuchWillIHaveToPayOutOfPocketForTreatmentIfMyInsuranceDoesntCoverMonkeypox,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-soon-can-i-start-coverage',
        name: 'HowSoonCanIStartCoverage',
        component: HowSoonCanIStartCoverage,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/how-travel-insurance-cover-covid-19',
        name: 'HowTravelInsuranceCoverCovid19',
        component: HowTravelInsuranceCoverCovid19,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/i-already-left-for-my-trip-is-it-too-late-to-buy-travel-insurance',
        name: 'IAlreadyLeftForMyTripIsItTooLateToBuyTravelInsurance',
        component: IAlreadyLeftForMyTripIsItTooLateToBuyTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/i-am-coming-to-study-in-the-united-states-what-policy-is-right-for-me',
        name: 'IAmComingToStudyInTheUnitedStatesWhatPolicyIsRightForMe',
        component: IAmComingToStudyInTheUnitedStatesWhatPolicyIsRightForMe,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/i-am-not-a-us-resident-can-i-still-purchase-a-policy',
        name: 'IAmNotAUSResidentCanIStillPurchaseAPolicy',
        component: IAmNotAUSResidentCanIStillPurchaseAPolicy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/i-am-traveling-in-a-group-is-a-single-policy-for-the-whole-group-available',
        name: 'IAmTravelingInAGroupIsASinglePolicyForTheWholeGroupAvailable',
        component: IAmTravelingInAGroupIsASinglePolicyForTheWholeGroupAvailable,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/i-am-traveling-with-small-children-what-happens-if-something-happens-to-me-during-the-trip',
        name: 'IAmTravelingWithSmallChildrenWhatHappensIfSomethingHappensToMeDuringTheTrip',
        component: IAmTravelingWithSmallChildrenWhatHappensIfSomethingHappensToMeDuringTheTrip,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/i-have-familyfriends-visiting-from-outside-the-united-states-what-policy-should-i-purchase',
        name: 'IHaveFamilyFriendsVisitingFromOutsideTheUnitedStatesWhatPolicyShouldIPurchase',
        component: IHaveFamilyFriendsVisitingFromOutsideTheUnitedStatesWhatPolicyShouldIPurchase,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/if-i-end-my-trip-early-can-cancel-my-policy-before-its-expiration',
        name: 'IfIEndMyTripEarlyCanCancelMyPolicyBeforeItsExpiration',
        component: IfIEndMyTripEarlyCanCancelMyPolicyBeforeItsExpiration,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/im-going-to-study-abroad-what-should-i-look-for-in-a-policy',
        name: 'ImGoingToStudyAbroadWhatShouldILookForInAPolicy',
        component: ImGoingToStudyAbroadWhatShouldILookForInAPolicy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/is-an-emergency-room-visit-covered-by-my-visitors-travel-medical-insurance',
        name: 'IsAnEmergencyRoomVisitCoveredByMyVisitorsTravelMedicalInsurance',
        component: IsAnEmergencyRoomVisitCoveredByMyVisitorsTravelMedicalInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/is-bypass-surgery-considered-a-pre-existing-medical-condition',
        name: 'IsBypassSurgeryConsideredAPreExistingMedicalCondition',
        component: IsBypassSurgeryConsideredAPreExistingMedicalCondition,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/is-pregnancy-covered',
        name: 'IsPregnancyCovered',
        component: IsPregnancyCovered,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/is-the-virtual-id-card-valid-or-should-i-wait-for-the-physical-card',
        name: 'IsTheVirtualIDCardValidOrShouldIWaitForThePhysicalCard',
        component: IsTheVirtualIDCardValidOrShouldIWaitForThePhysicalCard,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/is-there-a-limit-on-the-value-of-electronics-that-can-be-covered-by-travel-insurance',
        name: 'IsThereALimitOnTheValueOfElectronicsThatCanBeCoveredByTravelInsurance',
        component: IsThereALimitOnTheValueOfElectronicsThatCanBeCoveredByTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/is-there-a-monthly-payment-plan-available-for-visitors-insurance',
        name: 'IsThereAMonthlyPaymentPlanAvailableForVisitorsInsurance',
        component: IsThereAMonthlyPaymentPlanAvailableForVisitorsInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/is-travel-insurance-actually-worth-it',
        name: 'IsTravelInsuranceActuallyWorthIt',
        component: IsTravelInsuranceActuallyWorthIt,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/my-parents-have-not-arrived-in-the-united-states-yet-can-i-purchase-coverage-for-them',
        name: 'MyParentsHaveNotArrivedInTheUnitedStatesYetCanIPurchaseCoverageForThem',
        component: MyParentsHaveNotArrivedInTheUnitedStatesYetCanIPurchaseCoverageForThem,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/understanding-travel-insurance-claim-processing-time-what-to-expect',
        name: 'UnderstandingTravelInsuranceClaimProcessingTimeWhatToExpect',
        component: UnderstandingTravelInsuranceClaimProcessingTimeWhatToExpect,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-a-visa-letter-and-how-do-i-know-if-i-need-one',
        name: 'WhatAVisaLetterAndHowDoIKnowIfINeedOne',
        component: WhatAVisaLetterAndHowDoIKnowIfINeedOne,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-are-pre-existing-conditions',
        name: 'WhatArePreExistingConditions',
        component: WhatArePreExistingConditions,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-are-the-differences-between-fixed-and-comprehensive-visitors-insurance-plans',
        name: 'WhatAreTheDifferencesBetweenFixedAndComprehensiveVisitorsInsurancePlans',
        component: WhatAreTheDifferencesBetweenFixedAndComprehensiveVisitorsInsurancePlans,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-are-the-travel-insurance-requirements-for-a-schengen-visa',
        name: 'WhatAreTheTravelInsuranceRequirementsForASchengenVisa',
        component: WhatAreTheTravelInsuranceRequirementsForASchengenVisa,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-does-247-worldwide-support-and-claim-assistance-mean',
        name: 'WhatDoes247WorldwideSupportAndClaimAssistanceMean',
        component: WhatDoes247WorldwideSupportAndClaimAssistanceMean,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-happens-if-i-am-hospitalized-abroad',
        name: 'WhatHappensIfIAmHospitalizedAbroad',
        component: WhatHappensIfIAmHospitalizedAbroad,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-happens-if-i-miss-my-renewal-date',
        name: 'WhatHappensIfIMissMyRenewalDate',
        component: WhatHappensIfIMissMyRenewalDate,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-happens-if-my-luggage-is-lostdelayed',
        name: 'WhatHappensIfMyLuggageIsLostDelayed',
        component: WhatHappensIfMyLuggageIsLostDelayed,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-if-i-am-injured-in-a-car-accident-abroad',
        name: 'WhatIfIAmInjuredInACarAccidentAbroad',
        component: WhatIfIAmInjuredInACarAccidentAbroad,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-if-my-visa-status-changes',
        name: 'WhatIfMyVisaStatusChanges',
        component: WhatIfMyVisaStatusChanges,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-information-is-required-for-purchasing-a-visitors-insurance-plan',
        name: 'WhatInformationIsRequiredForPurchasingAVisitorsInsurancePlan',
        component: WhatInformationIsRequiredForPurchasingAVisitorsInsurancePlan,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-information-is-required-of-me-to-purchase-a-policy',
        name: 'WhatInformationIsRequiredOfMeToPurchaseAPolicy',
        component: WhatInformationIsRequiredOfMeToPurchaseAPolicy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-a-ppo',
        name: 'WhatIsAPPO',
        component: WhatIsAPPO,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-a-primary-care-physician',
        name: 'WhatIsAPrimaryCarePhysician',
        component: WhatIsAPrimaryCarePhysician,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-an-hmo',
        name: 'WhatIsAnHMO',
        component: WhatIsAnHMO,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-coronavirus-disease-2019-covid-19',
        name: 'WhatIsCoronavirusDisease2019COVID19',
        component: WhatIsCoronavirusDisease2019COVID19,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-pos',
        name: 'WhatIsPOS',
        component: WhatIsPOS,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-single-occupancy-coverage-and-what-types-of-situation-does-this-coverage-applies-to',
        name: 'WhatIsSingleOccupancyCoverageAndWhatTypesOfSituationDoesThisCoverageAppliesTo',
        component: WhatIsSingleOccupancyCoverageAndWhatTypesOfSituationDoesThisCoverageAppliesTo,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-the-difference-between-extension-and-renewal',
        name: 'WhatIsTheDifferenceBetweenExtensionAndRenewal',
        component: WhatIsTheDifferenceBetweenExtensionAndRenewal,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-the-difference-between-trip-cancellation-and-cancel-for-any-reason-travel-insurance',
        name: 'WhatIsTheDifferenceBetweenTripCancellationAndCancelForAnyReasonTravelInsurance',
        component: WhatIsTheDifferenceBetweenTripCancellationAndCancelForAnyReasonTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-the-maximum-age-limit-for-an-individual-to-be-eligible-for-adventure-travel-insurance',
        name: 'WhatIsTheMaximumAgeLimitForAnIndividualToBeEligibleForAdventureTravelInsurance',
        component: WhatIsTheMaximumAgeLimitForAnIndividualToBeEligibleForAdventureTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-travel-insurance',
        name: 'WhatIsTravelInsurance',
        component: WhatIsTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-is-travel-insurance-what-does-travel-insurance-cover',
        name: 'WhatIsTravelInsuranceAndWhatDoesItCover',
        component: WhatIsTravelInsuranceAndWhatDoesItCover,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-should-i-do-after-ive-purchased-an-hmo-plan',
        name: 'WhatShouldIDoAfterIvePurchasedAnHMOPlan',
        component: WhatShouldIDoAfterIvePurchasedAnHMOPlan,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-should-i-do-after-purchasing-a-ppo-plan',
        name: 'WhatShouldIDoAfterPurchasingAPPOPlan',
        component: WhatShouldIDoAfterPurchasingAPPOPlan,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-should-i-look-for-in-a-policy',
        name: 'WhatShouldILookForInAPolicy',
        component: WhatShouldILookForInAPolicy,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-should-i-look-for-in-a-policy-if-im-uncertain-about-how-long-ill-be-traveling',
        name: 'WhatShouldILookForInAPolicyIfImUncertainAboutHowLongIllBeTraveling',
        component: WhatShouldILookForInAPolicyIfImUncertainAboutHowLongIllBeTraveling,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/what-types-of-electronics-are-covered-by-travel-insurance',
        name: 'WhatTypesOfElectronicsAreCoveredByTravelInsurance',
        component: WhatTypesOfElectronicsAreCoveredByTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/whats-the-difference-between-urgent-care-and-emergency-services',
        name: 'WhatsTheDifferenceBetweenUrgentCareAndEmergencyServices',
        component: WhatsTheDifferenceBetweenUrgentCareAndEmergencyServices,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/when-is-travel-insurance-worth-it',
        name: 'WhenIsTravelInsuranceWorthIt',
        component: WhenIsTravelInsuranceWorthIt,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/when-should-i-buy-coverage',
        name: 'WhenShouldIBuyCoverage',
        component: WhenShouldIBuyCoverage,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/when-should-i-buy-travel-insurance',
        name: 'WhenShouldIBuyTravelInsurance',
        component: WhenShouldIBuyTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/who-is-eligible-for-adventure-travel-insurance',
        name: 'WhoIsEligibleForAdventureTravelInsurance',
        component: WhoIsEligibleForAdventureTravelInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/who-is-eligible-for-visitor-medical-insurance',
        name: 'WhoIsEligibleForVisitorMedicalInsurance',
        component: WhoIsEligibleForVisitorMedicalInsurance,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/who-should-i-call-if-i-have-problems-with-my-flightcruise',
        name: 'WhoShouldICallIfIHaveProblemsWithMyFlightCruise',
        component: WhoShouldICallIfIHaveProblemsWithMyFlightCruise,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/why-are-travel-insurance-plans-cheaper-than-domestic-health-insurance-plans',
        name: 'WhyAreTravelInsurancePlansCheaperThanDomesticHealthInsurancePlans',
        component: WhyAreTravelInsurancePlansCheaperThanDomesticHealthInsurancePlans,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/why-should-i-purchase-a-separate-policy-for-people-70-and-older',
        name: 'WhyShouldIPurchaseASeparatePolicyForPeople70AndOlder',
        component: WhyShouldIPurchaseASeparatePolicyForPeople70AndOlder,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/will-my-travel-insurance-cover-me-if-i-need-to-be-evacuated-due-to-civil-unrest',
        name: 'WillMyTravelInsuranceCoverMeIfINeedToBeEvacuatedDueToCivilUnrest',
        component: WillMyTravelInsuranceCoverMeIfINeedToBeEvacuatedDueToCivilUnrest,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/will-my-travel-insurance-cover-me-if-there-is-civil-unrest-at-my-destination',
        name: 'WillMyTravelInsuranceCoverMeIfThereIsCivilUnrestAtMyDestination',
        component: WillMyTravelInsuranceCoverMeIfThereIsCivilUnrestAtMyDestination,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
    {
        path: '/faqs/will-plans-cover-baggage-loss-and-trip-cancellation',
        name: 'WillPlansCoverBaggageLossAndTripCancellation',
        component: WillPlansCoverBaggageLossAndTripCancellation,
        meta: {
            parentPath: "faq",
        },
        props: {footer: true},
    },
];

export default routes;
*/

// Import all FAQ components dynamically
const components = {
    'i-have-familyfriends-visiting-from-outside-the-united-states-what-policy-should-i-purchase': () => import('@/pages/faqs/i-have-familyfriends-visiting-from-outside-the-united-states-what-policy-should-i-purchase.vue'),
    'who-does-travel-insurance-cover': () => import('@/pages/faqs/who-does-travel-insurance-cover.vue'),
    'here-faq-title-edit': () => import('@/pages/faqs/here-faq-title-edit.vue'),
    'is-bypass-surgery-considered-a-pre-existing-medical-condition': () => import('@/pages/faqs/is-bypass-surgery-considered-a-pre-existing-medical-condition.vue'),
    'can-travel-insurance-cover-covid-19': () => import('@/pages/faqs/can-travel-insurance-cover-covid-19.vue'),
    'how-can-you-access-your-travel-insurance-policy-documents-online': () => import('@/pages/faqs/how-can-you-access-your-travel-insurance-policy-documents-online.vue'),
    'is-there-a-monthly-payment-plan-available-for-usa-visitors-medical-insurance': () => import('@/pages/faqs/is-there-a-monthly-payment-plan-available-for-usa-visitors-medical-insurance.vue'),
    'what-if-my-visa-status-changes': () => import('@/pages/faqs/what-if-my-visa-status-changes.vue'),
    'when-should-i-buy-travel-insurance': () => import('@/pages/faqs/when-should-i-buy-travel-insurance.vue'),
    'can-i-cancel-my-trip-due-to-civil-unrest-and-be-reimbursed-by-my-travel-insurance': () => import('@/pages/faqs/can-i-cancel-my-trip-due-to-civil-unrest-and-be-reimbursed-by-my-travel-insurance.vue'),
    'what-are-the-differences-between-fixed-and-comprehensive-visitors-insurance-plans': () => import('@/pages/faqs/what-are-the-differences-between-fixed-and-comprehensive-visitors-insurance-plans.vue'),
    'does-visitors-insurance-cover-urgent-care-visits': () => import('@/pages/faqs/does-visitors-insurance-cover-urgent-care-visits.vue'),
    'can-i-change-my-primary-care-physician': () => import('@/pages/faqs/can-i-change-my-primary-care-physician.vue'),
    'how-does-travel-insurance-work': () => import('@/pages/faqs/how-does-travel-insurance-work.vue'),
    'who-should-i-call-if-i-have-problems-with-my-flightcruise': () => import('@/pages/faqs/who-should-i-call-if-i-have-problems-with-my-flightcruise.vue'),
    'are-you-planning-a-trip-to-the-usa-and-curious-about-how-visitors-health-insurance-works': () => import('@/pages/faqs/are-you-planning-a-trip-to-the-usa-and-curious-about-how-visitors-health-insurance-works.vue'),
    'can-visitors-insurance-be-canceled': () => import('@/pages/faqs/can-visitors-insurance-be-canceled.vue'),
    'can-you-extend-your-travel-insurance-policy': () => import('@/pages/faqs/can-you-extend-your-travel-insurance-policy.vue'),
    'will-plans-cover-baggage-loss-and-trip-cancellation': () => import('@/pages/faqs/will-plans-cover-baggage-loss-and-trip-cancellation.vue'),
    'who-is-eligible-for-visitor-medical-insurance': () => import('@/pages/faqs/who-is-eligible-for-visitor-medical-insurance.vue'),
    'can-you-mail-my-id-card-to-me': () => import('@/pages/faqs/can-you-mail-my-id-card-to-me.vue'),
    'how-do-i-choose-a-co-insurance-amount-when-buying-usa-visitors-insurance': () => import('@/pages/faqs/how-do-i-choose-a-co-insurance-amount-when-buying-usa-visitors-insurance.vue'),
    'what-is-the-difference-between-extension-and-renewal': () => import('@/pages/faqs/what-is-the-difference-between-extension-and-renewal.vue'),
    'can-i-assign-my-insurance-benefits-to-a-hospital-physician-or-other-provider': () => import('@/pages/faqs/can-i-assign-my-insurance-benefits-to-a-hospital-physician-or-other-provider.vue'),
    'i-already-left-for-my-trip-is-it-too-late-to-buy-travel-insurance': () => import('@/pages/faqs/i-already-left-for-my-trip-is-it-too-late-to-buy-travel-insurance.vue'),
    'what-should-i-look-for-in-a-policy': () => import('@/pages/faqs/what-should-i-look-for-in-a-policy.vue'),
    'what-is-the-difference-between-trip-cancellation-and-cancel-for-any-reason-travel-insurance': () => import('@/pages/faqs/what-is-the-difference-between-trip-cancellation-and-cancel-for-any-reason-travel-insurance.vue'),
    'can-visitors-insurance-be-bought-after-booking': () => import('@/pages/faqs/can-visitors-insurance-be-bought-after-booking.vue'),
    'how-does-payment-for-medical-treatment-work-with-visitors-travel-health-insurance': () => import('@/pages/faqs/how-does-payment-for-medical-treatment-work-with-visitors-travel-health-insurance.vue'),
    'can-i-transfer-my-travel-insurance-to-a-different-trip': () => import('@/pages/faqs/can-i-transfer-my-travel-insurance-to-a-different-trip.vue'),
    'how-early-should-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa': () => import('@/pages/faqs/how-early-should-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa.vue'),
    'when-is-travel-insurance-worth-it': () => import('@/pages/faqs/when-is-travel-insurance-worth-it.vue'),
    'is-there-a-limit-on-the-value-of-electronics-that-can-be-covered-by-travel-insurance': () => import('@/pages/faqs/is-there-a-limit-on-the-value-of-electronics-that-can-be-covered-by-travel-insurance.vue'),
    'do-i-need-to-get-a-physical-exam-or-disclose-anything-from-my-medical-records-to-purchase-a-plan': () => import('@/pages/faqs/do-i-need-to-get-a-physical-exam-or-disclose-anything-from-my-medical-records-to-purchase-a-plan.vue'),
    'what-happens-if-i-am-hospitalized-abroad': () => import('@/pages/faqs/what-happens-if-i-am-hospitalized-abroad.vue'),
    'is-travel-insurance-really-necessary-for-international-travel': () => import('@/pages/faqs/is-travel-insurance-really-necessary-for-international-travel.vue'),
    'how-do-i-file-a-claim': () => import('@/pages/faqs/how-do-i-file-a-claim.vue'),
    'what-should-i-look-for-in-a-policy-if-im-uncertain-about-how-long-ill-be-traveling': () => import('@/pages/faqs/what-should-i-look-for-in-a-policy-if-im-uncertain-about-how-long-ill-be-traveling.vue'),
    'can-i-buy-travel-insurance-after-booking-my-trip': () => import('@/pages/faqs/can-i-buy-travel-insurance-after-booking-my-trip.vue'),
    'how-can-i-access-the-details-of-my-visitors-travel-health-insurance-plan-after-purchase': () => import('@/pages/faqs/how-can-i-access-the-details-of-my-visitors-travel-health-insurance-plan-after-purchase.vue'),
    'what-should-i-do-after-purchasing-a-ppo-plan': () => import('@/pages/faqs/what-should-i-do-after-purchasing-a-ppo-plan.vue'),
    'what-are-pre-existing-conditions': () => import('@/pages/faqs/what-are-pre-existing-conditions.vue'),
    'how-travel-insurance-cover-covid-19': () => import('@/pages/faqs/how-travel-insurance-cover-covid-19.vue'),
    'is-there-a-monthly-payment-plan-available-for-visitors-insurance': () => import('@/pages/faqs/is-there-a-monthly-payment-plan-available-for-visitors-insurance.vue'),
    'how-do-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa': () => import('@/pages/faqs/how-do-i-buy-visitors-travel-medical-insurance-for-my-trip-to-the-usa.vue'),
    'what-is-travel-insurance': () => import('@/pages/faqs/what-is-travel-insurance.vue'),
    'what-is-a-ppo': () => import('@/pages/faqs/what-is-a-ppo.vue'),
    'why-should-i-purchase-a-separate-policy-for-people-70-and-older': () => import('@/pages/faqs/why-should-i-purchase-a-separate-policy-for-people-70-and-older.vue'),
    'does-my-insurance-policy-cover-treatment-for-monkeypox': () => import('@/pages/faqs/does-my-insurance-policy-cover-treatment-for-monkeypox.vue'),
    'will-my-travel-insurance-cover-me-if-i-need-to-be-evacuated-due-to-civil-unrest': () => import('@/pages/faqs/will-my-travel-insurance-cover-me-if-i-need-to-be-evacuated-due-to-civil-unrest.vue'),
    'are-there-any-age-limits': () => import('@/pages/faqs/are-there-any-age-limits.vue'),
    'what-if-i-am-injured-in-a-car-accident-abroad': () => import('@/pages/faqs/what-if-i-am-injured-in-a-car-accident-abroad.vue'),
    'what-is-single-occupancy-coverage-and-what-types-of-situation-does-this-coverage-applies-to': () => import('@/pages/faqs/what-is-single-occupancy-coverage-and-what-types-of-situation-does-this-coverage-applies-to.vue'),
    'what-happens-if-i-miss-my-renewal-date': () => import('@/pages/faqs/what-happens-if-i-miss-my-renewal-date.vue'),
    'im-going-to-study-abroad-what-should-i-look-for-in-a-policy': () => import('@/pages/faqs/im-going-to-study-abroad-what-should-i-look-for-in-a-policy.vue'),
    'my-parents-have-not-arrived-in-the-united-states-yet-can-i-purchase-coverage-for-them': () => import('@/pages/faqs/my-parents-have-not-arrived-in-the-united-states-yet-can-i-purchase-coverage-for-them.vue'),
    'how-do-i-find-a-doctor-within-the-ppo-network-of-my-comprehensive-usa-visitors-insurance-plan': () => import('@/pages/faqs/how-do-i-find-a-doctor-within-the-ppo-network-of-my-comprehensive-usa-visitors-insurance-plan.vue'),
    'what-should-i-do-after-ive-purchased-an-hmo-plan': () => import('@/pages/faqs/what-should-i-do-after-ive-purchased-an-hmo-plan.vue'),
    'does-travel-insurance-cover-quarantine-hotel-costs': () => import('@/pages/faqs/does-travel-insurance-cover-quarantine-hotel-costs.vue'),
    'i-am-traveling-in-a-group-is-a-single-policy-for-the-whole-group-available': () => import('@/pages/faqs/i-am-traveling-in-a-group-is-a-single-policy-for-the-whole-group-available.vue'),
    'can-i-buy-visitors-insurance-after-ive-already-started-my-trip': () => import('@/pages/faqs/can-i-buy-visitors-insurance-after-ive-already-started-my-trip.vue'),
    'can-i-purchase-visitors-insurance-if-i-am-transitioning-to-permanent-residency-in-the-usa': () => import('@/pages/faqs/can-i-purchase-visitors-insurance-if-i-am-transitioning-to-permanent-residency-in-the-usa.vue'),
};

const routes = Object.keys(components).map(slug => {
    return {
        path: `/faqs/${slug}`,
        name: slug.replace(/-/g, '_'),
        component: components[slug],
        meta: { parentPath: "faq" },
        props: { footer: true },
    };
});
export default routes;
